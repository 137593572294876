import { createTheme, Slide, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useStateIfMounted } from "use-state-if-mounted";
import Main from "./layouts/Main/Main";
import { companyService } from "./services/companyService";
import BasketProvider from "./store/BasketProvider";
import LoginProvider from "./store/LoginProvider";
import ProductProvider from "./store/ProductProvider";

export default function App() {
  const [logo, setLogo] = useStateIfMounted("");
  const [storeURLs, setStoreURLs] = useStateIfMounted("");

  useEffect(() => {
    const companyInfoByStorage = sessionStorage.getItem("company_info");
    if (companyInfoByStorage) {
      const companyInfo = JSON.parse(companyInfoByStorage);
      setLogo(companyInfo.logo);
      document.title = companyInfo.name;
    } else {
      companyService.getCompanyInfo().then((response) => {
        setLogo(response.logo);
        document.title = response.name;
        sessionStorage.setItem("company_info", JSON.stringify(response));
      });
    }
    const storeURLsByStorage = sessionStorage.getItem("storeURLs");
    if (storeURLsByStorage) {
      const storeURLs = JSON.parse(storeURLsByStorage);
      setStoreURLs(storeURLs);
    } else {
      companyService.getStoreURLs().then((response) => {
        setStoreURLs(response);
        sessionStorage.setItem("storeURLs", JSON.stringify(response));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      autoHideDuration={1000}
      TransitionComponent={Slide}
    >
      <ProductProvider>
        <LoginProvider>
          <BasketProvider>
            <ThemeProvider
              theme={createTheme({
                components: {
                  MuiListItemButton: {
                    defaultProps: {
                      disableTouchRipple: true,
                    },
                  },
                },
                palette: {
                  mode: "light",
                  primary: { main: "#5d3ebc" },
                  // background: { paper: "rgb(5, 30, 52)" },
                },
              })}
            >
              <BrowserRouter>
                <Routes>
                  <Route
                    path="/*"
                    element={
                      <Main
                        logo={logo}
                        storeURLs={storeURLs}
                        title={document.title}
                      />
                    }
                  />
                </Routes>
              </BrowserRouter>
            </ThemeProvider>
          </BasketProvider>
        </LoginProvider>
      </ProductProvider>
    </SnackbarProvider>
  );
}
